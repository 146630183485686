import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_WRAP_BEGIN,
  FETCH_WRAP_SUCCESS,
  FETCH_WRAP_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import {
  erc20ABI,
  contracts,
  tokens,
  apiBaseUrl
} from "../../configure";
import {
  convertAmountFromRawNumber,
  convertAmountToRawNumber
} from "../../helpers/bignumber";
import axios from "axios";
import _ from "lodash";

function apiRequestUrl(methodName, queryParams) {
  return apiBaseUrl + methodName + '?' + (new URLSearchParams(queryParams)).toString();
}
export function fetchSwapDetail({ web3, address, inputToken,outputToken, inputData }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_WRAP_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try{
   
        const multicall = new MultiCall(web3, contracts.multicall.address);
        const tokenAddress = tokens.basicRewardAddress
        const inputTokenAddress = inputToken.address
        const outputTokenAddress = outputToken.address
        const ethAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        const inputContract = new web3.eth.Contract(erc20ABI, inputTokenAddress);
        const outputContract = new web3.eth.Contract(erc20ABI, outputTokenAddress);
        const tokenContract = new web3.eth.Contract(erc20ABI, tokenAddress);
        let outputValue = ""
        const promises = []
        promises.push(axios.get(apiRequestUrl('/tokens', {})))
        if(inputData && inputData > 0){
          promises.push(axios.get(apiRequestUrl('/quote', {fromTokenAddress:inputTokenAddress,toTokenAddress:outputTokenAddress, amount:convertAmountToRawNumber(inputData,inputToken.decimals)})))
        } else {
          promises.push("");
        }
        const requestData =  await Promise.all(promises)
        if(inputData && inputData > 0){
          outputValue = convertAmountFromRawNumber(requestData[1].data.toTokenAmount, outputToken.decimals)
        } else {
          outputValue = 0;
        }
        
        let outputBalance = 0
        let inputBalance = 0
        let inputAllowance = 0
        const calls = [
          { result: outputContract.methods.balanceOf(address) },
          { result: inputContract.methods.balanceOf(address) },
          { result: inputContract.methods.allowance(address,contracts.oneInchRouter.address) },
          { result: tokenContract.methods.balanceOf(address) },
       
        ];
        const [results] = await multicall.all([calls])
        outputBalance = results[0].result
            ? convertAmountFromRawNumber(results[0].result, outputToken.decimals)
            : 0;
        inputBalance = results[1].result
        ? convertAmountFromRawNumber(results[1].result, inputToken.decimals)
        : 0;
        inputAllowance = results[2].result
        ? convertAmountFromRawNumber(results[2].result, inputToken.decimals)
        : 0;
        const tokenBalance = results[3].result
            ? convertAmountFromRawNumber(results[3].result)
            : 0;
        const ethResult= await web3.eth.getBalance(address)
        const ethBalance = ethResult
            ? convertAmountFromRawNumber(ethResult)
            : 0;
      
        if( inputTokenAddress == ethAddress){
          inputBalance = ethBalance
        }
        if( outputTokenAddress == ethAddress){
          outputBalance = ethBalance
        }
        inputBalance = (Math.floor(inputBalance*1e6)/1e6).toString()
        outputBalance = (Math.floor(outputBalance*1e6)/1e6).toString()
        const output = {
          outputBalance,
          inputBalance,
          outputValue,
          tokenBalance,
          tokenList: _.reduce(requestData[0].data.tokens,function(result, value, key){
            result.push(value)
            return result;
          },[]),
          allowance : inputTokenAddress == ethAddress?"115792089237316195423570985008687907853269984665640564039457584007913129639935":inputAllowance,
        };
        dispatch({
          type: FETCH_WRAP_SUCCESS,
          data: output,
        });

      }catch(error){
        dispatch({
          type: FETCH_WRAP_FAILURE,
        });
        console.log(error)
      }
     
    });

    return promise;
  };
}

export function useFetchSwapDetail() {
  const dispatch = useDispatch();

  const { detail, fetchSwapDetailPending } = useSelector(
    (state) => ({
      fetchSwapDetailPending: state.swap.fetchSwapDetailPending,
      detail: state.swap.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchSwapDetail(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchSwapDetail: boundAction,
    fetchSwapDetailPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_WRAP_BEGIN:
      return {
        ...state,
        fetchSwapDetailPending: true,
      };

    case FETCH_WRAP_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchSwapDetailPending: false,
      };

    case FETCH_WRAP_FAILURE:
      return {
        ...state,
        fetchSwapDetailPending: false,
      };

    default:
      return state;
  }
}
