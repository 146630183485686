import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useConnectWallet } from "features/home/redux/hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomTable from "components/CustomTable/CustomTable.js";
import Pagination from '@material-ui/lab/Pagination';
import _ from "lodash";
import MeterialButton from '@material-ui/core/Button';
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { useFetchLeaderboardDetail, useFetchLeaderboardRank } from "./redux/hooks";
import Popover from '@material-ui/core/Popover';
import { useFetchPrice } from "../price/redux/hooks";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";
import Tabs from "components/CustomTabs/Tabs.js";
import { Link } from "react-router-dom";
import { formatCountdown } from "features/helpers/format";
import truncateEthAddress from "features/helpers/ethAddress";
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.1,
    color: "white",
  }, 
  popover: {
    pointerEvents: 'none',
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  popPaper: {
    backgroundColor: theme.palette.background.paper,
    minWidth:100,
    padding:10
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "white",
  },
}))(LinearProgress);

const Leaderboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [rankNumber, setRankNumber] = useState("");
  const [selectedButton, setSelectedButton] = useState();
  const { web3, address } = useConnectWallet();
  const { fetchPrice, priceData } = useFetchPrice();
  const [limit, setLimit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const [now, setNow] = useState(moment());
  const [dateButtons, setDateButtons] = React.useState([]);
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };
  //TODO: check project settings
  const name = "yggsea"
  let startTime = 6652108400
  const duration = 20

  const hourMapping = {
    0:5,
    1:5,
    2:5,
    3:5,
    4:5,
    5:11,
    6:11,
    7:11,
    8:11,
    9:11,
    10:11,
    11:17,
    12:17,
    13:17,
    14:17,
    15:17,
    16:17,
    17:23,
    18:23,
    19:23,
    20:23,
    21:23,
    22:23,
    23:29
  }

  let deadline = moment().set({
    hour: hourMapping[now.hours()],
    minute: 0,
    second: 0,
    millisecond: 0,
  });
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(moment());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [now]);
  let countdown = formatCountdown(deadline, now);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const { fetchLeaderboardDetail, fetchLeaderboardDetailPending, detail } =
    useFetchLeaderboardDetail();
  const { fetchLeaderboardRank, fetchLeaderboardRankPending, rank } =
    useFetchLeaderboardRank();

  const [anchorEl, setAnchorEl] = React.useState({});
  const handlePopoverOpen = (event, name, amount) => {
    setAnchorEl({target:event.currentTarget,name,amount});
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const open = Boolean(anchorEl.target);
  const max = tabValue == 0 ? 500: 1000
  const progressValue = rankNumber
    ? rankNumber < max
      ? rankNumber < (max/2)
        ? rankNumber < 50
          ? 100
          : 75
        : 50
      : 25
    : 0;

  useEffect(() => {
    if (web3 && address) {
      fetchLeaderboardDetail({ name, limit, timestamp: selectedButton, page, search,tabValue});
      fetchLeaderboardRank({ name, timestamp: selectedButton, search: address,tabValue});
      fetchPrice({web3});
      const id = setInterval(() => {
        fetchLeaderboardDetail({ name, limit, timestamp: selectedButton, page, search,tabValue});
        fetchLeaderboardRank({ name, timestamp: selectedButton, search: address,tabValue});
        fetchPrice({web3});
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address, name, limit, selectedButton, page, search,tabValue]);

  const handleChangeLimit = (newLimit) => {
    if (limit != newLimit) {
      setLimit(newLimit);
    }
  };

  useEffect(() => {
    // if (web3 && address) {
      let btns = []
      for(let i=1; i<=duration; i++) {
        let disabled = Math.floor(Date.now()/1000) < startTime
        if(!disabled) {
          setSelectedButton(startTime)
        }
        btns.push({ label: `Day ${Math.ceil(i/4)}${String.fromCharCode(97 + (i-1)%4)}`, value: startTime, disabled })
        startTime+=21600
      }

      setDateButtons(btns)
    // }
  }, []);

  useEffect(() => {
    if(detail.count) {
      setTotalCount(Number(detail.count))
    }
  }, [detail]);

  useEffect(() => {
    if(rank) {
      setRankNumber(_.get(rank, 'users.0.rank'), '')
    }
  }, [rank]);
  
  const leaderboard = detail.users ? detail.users : [];
  const listData = leaderboard
  
  // const listData = [{rank:1,address:"test"},{rank:2,address:"test"},{rank:3,address:"test"},{rank:900,address:"test"},{rank:1002,address:"test"}]
  // const listData = _.filter(leaderboard, function (o) {
  //   if (!search) return o;
  //   return _.startsWith(o.address, search);
  // });
  const changeInputValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  // const userRank = _.find(leaderboard, { address: address })
  //   ? parseInt(_.find(leaderboard, { address: address }).rank)
  //   : "";
  const renderRank = (r)=>{
    return(
    <span style={{border:"1px solid white", borderRadius:12,fontSize:34, padding:"0px 5px",  margin:'0 10px', paddingTop:10}}>
      #{r?r:"-"}
    </span>
    )
  }
  const renderTop = (r)=>{
    return(
    <span style={{border:"1px solid white", borderRadius:12,fontSize:18, padding:5, width:50, 
    display:'inline-block', textAlign:"left", margin:'0 10px', lineHeight:1.2}}>
        Top<br/>
        {r}%
    </span>
    )
  }
  const renderRewardIcon = (r)=>{
    return(
      <img src={r.image}   
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event)=>handlePopoverOpen(event,r.name,r.amount)}
        onMouseLeave={handlePopoverClose} 
        className="icon" />
      )
  }


  const renderOverallReward = (amount) => {
    return [renderRewardIcon({ name: "SEA", image: require("assets/img/sea.png").default, amount: amount })
    , renderRewardIcon({ name: "YGG", image: require("assets/img/YGG.png").default, amount: "The $YGG token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "IndiGG", image: require("assets/img/IndiGG.png").default, amount: "The $INDI token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "BREED", image: require("assets/img/BreedDAO.png").default, amount: "The $BREED token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "GUILD", image: require("assets/img/GUILD.png").default, amount: "The $GUILD token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "RBW", image: require("assets/img/RBW.png").default, amount: "The $RBW token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "FNC", image: require("assets/img/FNC.png").default, amount: "The $FNC token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "XY", image: require("assets/img/XY.png").default, amount: "The $XY token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "KYOKO", image: require("assets/img/Kyoto.png").default, amount: "The $KYOKO token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })
    , renderRewardIcon({ name: "NITRO", image: require("assets/img/Nitro.png").default, amount: "The $NITRO token will beging to be airdropped to the Top 1,000 stakers after the 6 monhts lockup period , according to the Leaderboard result on May 14, 2022 3:00 p.m. UTC." })]
  }

  const renderReward = (r)=>{
    if(tabValue == 0){
      if(selectedButton == 1652108400 ||selectedButton == 1652130000||selectedButton == 1652151600){
        if(r < 2){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:2450})]
        } 
  
        if(r < 3){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:2013})]
        } 
  
        if(r < 4){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1663})]
        } 
  
        if(r < 6){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1312.5})]
        } 
  
        if(r < 11){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1050})]
        } 
  
        if(r < 26){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:875})]
        } 
  
        if(r < 51){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:665})]
        } 
  
        if(r < 76){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:595})]
        } 
  
        if(r < 101){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:525})]
        } 
  
        if(r < 151){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:385})]
        } 
  
        if(r < 201){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:350})]
        } 
  
        if(r < 251){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:315})]
        } 
  
        if(r < 301){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:280})]
        } 
  
        if(r < 401){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:192.5})]
        } 
  
        if(r < 501){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:175})]
        } 
      } else {
        if(r < 2){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:7000})]
        } 
  
        if(r < 3){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:5250})]
        } 
  
        if(r < 4){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:3500})]
        } 
  
        if(r < 6){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:3063})]
        } 
  
        if(r < 11){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:2450})]
        } 
  
        if(r < 26){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1750})]
        } 
  
        if(r < 51){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1750})]
        } 
  
        if(r < 76){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1540})]
        } 
  
        if(r < 101){
          return [renderRewardIcon({name:"SEA", image:require("assets/img/sea.png").default, amount:1295})]
        } 
      }
      

    } else {
      if(r < 2){
        return renderOverallReward(18750);

      } 

      if(r < 3){
        return renderOverallReward(12750);
      } 

      if(r < 4){
        return renderOverallReward(9450);
      } 

      if(r < 6){
        return renderOverallReward(8250);
      } 

      if(r < 11){
        return renderOverallReward(7080);
      } 

      if(r < 26){
        return renderOverallReward(4720);
      } 

      if(r < 51){
        return renderOverallReward(3540);
      } 

      if(r < 76){
        return renderOverallReward(3186);
      } 

      if(r < 101){
        return renderOverallReward(2832);
      } 

      if(r < 151){
        return renderOverallReward(2655);
      } 

      if(r < 201){
        return renderOverallReward(2478);
      } 

      if(r < 251){
        return renderOverallReward(2301);
      } 

      if(r < 301){
        return renderOverallReward(2124);
      } 

      if(r < 401){
        return renderOverallReward(1593);
      } 

      if(r < 501){
        return renderOverallReward(1416);
      } 

      if(r < 751){
        return renderOverallReward(708);
      } 

      if(r < 1001){
        return renderOverallReward(566.4);
      } 
   
    }
  }

  const renderEligibleMark = (stakeAmount,minimum) => {
    return stakeAmount>=minimum? <Check style={{color:"#00C389"}}/>:<Close style={{color:"#D10000"}} onMouseEnter={(event)=>handlePopoverOpen(event,"Not eligible due to insufficient staked amount",`minimum required amount of staking - ${minimum} SEA`)} onMouseLeave={handlePopoverClose} />;
  }

  const renderEligible = (row, tabValue) => {
    let r = row.rank;
    let stakeAmount = tabValue == 0?row.diffTokenStaked:row.singleTokenStaked;

    if(tabValue == 0){
      if(selectedButton == 1652108400 ||selectedButton == 1652130000||selectedButton == 1652151600){
        if(r < 2){
          return renderEligibleMark(stakeAmount, 2450/2);
        } 
    
        if(r < 3){
          return renderEligibleMark(stakeAmount, 2013/2);
        } 
    
        if(r < 4){
          return renderEligibleMark(stakeAmount, 1663/2);
        } 
    
        if(r < 6){
          return renderEligibleMark(stakeAmount, 1312.5/2);
        } 
    
        if(r < 11){
          return renderEligibleMark(stakeAmount, 1050/2);
        } 
    
        if(r < 26){
          return renderEligibleMark(stakeAmount, 875/2);
        } 
    
        if(r < 51){
          return renderEligibleMark(stakeAmount, 665/2);
        } 
    
        if(r < 76){
          return renderEligibleMark(stakeAmount, 595/2);
        } 
    
        if(r < 101){
          return renderEligibleMark(stakeAmount, 525/2);
        } 
    
        if(r < 151){
          return renderEligibleMark(stakeAmount, 385/2);
        } 
    
        if(r < 201){
          return renderEligibleMark(stakeAmount, 350/2);
        } 
    
        if(r < 251){
          return renderEligibleMark(stakeAmount, 315/2);
        } 
    
        if(r < 301){
          return renderEligibleMark(stakeAmount, 280/2);
        } 
    
        if(r < 401){
          return renderEligibleMark(stakeAmount, 192.5/2);
        } 
    
        if(r < 501){
          return renderEligibleMark(stakeAmount, 175/2);
        } 
      } else {
        if(r < 2){
          return renderEligibleMark(stakeAmount, 7000/2);
        } 
    
        if(r < 3){
          return renderEligibleMark(stakeAmount, 5250/2);
        } 
    
        if(r < 4){
          return renderEligibleMark(stakeAmount, 3500/2);
        } 
    
        if(r < 6){
          return renderEligibleMark(stakeAmount, 3063/2);
        } 
    
        if(r < 11){
          return renderEligibleMark(stakeAmount, 2450/2);
        } 
    
        if(r < 26){
          return renderEligibleMark(stakeAmount, 1750/2);
        } 
    
        if(r < 51){
          return renderEligibleMark(stakeAmount, 1750/2);
        } 
    
        if(r < 76){
          return renderEligibleMark(stakeAmount, 1540/2);
        } 
    
        if(r < 101){
          return renderEligibleMark(stakeAmount, 1295/2);
        } 
      }
    } else {
      if(r < 2){
        return renderEligibleMark(stakeAmount,18750/2);
      } 

      if(r < 3){
        return renderEligibleMark(stakeAmount,12750/2);
      } 

      if(r < 4){
        return renderEligibleMark(stakeAmount,9450/2);
      } 

      if(r < 6){
        return renderEligibleMark(stakeAmount,8250/2);
      } 

      if(r < 11){
        return renderEligibleMark(stakeAmount,7080/2);
      } 

      if(r < 26){
        return renderEligibleMark(stakeAmount,4720/2);
      } 

      if(r < 51){
        return renderEligibleMark(stakeAmount,3540/2);
      } 

      if(r < 76){
        return renderEligibleMark(stakeAmount,3186/2);
      } 

      if(r < 101){
        return renderEligibleMark(stakeAmount,2832/2);
      } 

      if(r < 151){
        return renderEligibleMark(stakeAmount,2655/2);
      } 

      if(r < 201){
        return renderEligibleMark(stakeAmount,2478/2);
      } 

      if(r < 251){
        return renderEligibleMark(stakeAmount,2301/2);
      } 

      if(r < 301){
        return renderEligibleMark(stakeAmount,2124/2);
      } 

      if(r < 401){
        return renderEligibleMark(stakeAmount,1593/2);
      } 

      if(r < 501){
        return renderEligibleMark(stakeAmount,1416/2);
      } 

      if(r < 751){
        return renderEligibleMark(stakeAmount,708/2);
      } 

      if(r < 1001){
        return renderEligibleMark(stakeAmount,566.4/2);
      } 
    }

    
  }
  const renderBoard = () =>{
    return(
      <>
       <Grid xs={12}>
          <div className="card" style={{ textAlign: "left" }}>
          <div style={{float:"right", margin:"15px 0", verticalAlign:"middle"}}>
              
              {renderRank(rankNumber)}
            </div>
            <h2>You : <span style={{fontSize:computer?20:13}}>{address}</span></h2>
            <div style={{marginTop:56}}>
            <BorderLinearProgress variant="determinate" value={progressValue} />
            </div>
          
            <Grid
              container
              spacing={3}
              style={{ margin: "5px", textAlign: "right" }}
            >
              <Grid item sm={2} />
              <Grid item sm={2}>
                <span>Top {max}</span>
              </Grid>
              <Grid item sm={4}>
              <span>Top {max/2}</span>
              </Grid>
              <Grid item sm={4}>
                <span>Top 50</span>
              </Grid>
            </Grid>
            <div style={{textAlign:"right"}}>
              <span style={{marginRight:10}}>Snapshot is taken every 6 hours. 
                  Next snapshot in {countdown.hours} : {countdown.minutes}{" "}
                  : {countdown.seconds}
              </span>
              <Link to={"/"}><Button color="secondary" >Stake</Button></Link>
            </div>
            
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <div className="card">
            <Grid container alignItems="flex-end">
              <Grid item xs={12} >
                
              </Grid>
              <Grid item xs={12} sm={8}>
              <div style={{textAlign:"left"}}>
                
                {tabValue == 0 && dateButtons.map((button, index) => {
                return (
                  <MeterialButton key={button.label}
                    color="primary"
                    disabled={button.disabled}
                    variant={selectedButton == button.value ? "contained" : "outlined"}
                    style={{ margin: 5, fontSize: 15, padding:6 }}
                    onClick={() => {
                      setPage(1)
                      setSelectedButton(button.value)
                      
                      
                    }}>
                    {button.label}
                  </MeterialButton>
                )
              })}
                {!search && <div style={{width:"100%"}}><Pagination count={Math.ceil(totalCount/50)} 
                  color="primary" page={page} 
                  onChange={handleChange} showFirstButton showLastButton 
                  defaultPage={6} boundaryCount={2}/></div>}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomOutlinedInput
                  value={search}
                  placeholder="search address"
                  onClick={() => {}}
                  onChange={changeInputValue}
                />
              </Grid>
            </Grid>
        
          

            <CustomTable
              leftText={{}}
              headers={["Rank", `Address`,"Staked Amount" ,"Points", "Rewards","Eligibility"]}
              contents={listData.map((row, index) => {
                
                  return [
                    `#${row.rank}`,
                    truncateEthAddress(row.address),
                    `${parseFloat(tabValue == 0 ?row.diffTokenStaked:row.singleTokenStaked).toFixed(0)}`,
                    `${parseFloat(tabValue == 0 ?row.diffBadges:row.badges).toFixed(0)}`,
                    renderReward(row.rank),
                    renderEligible(row,tabValue)
                  ];
                })}
            />
            <hr style={{margin:"30px 0"}}/>
            
          </div>
        </Grid>
      </>
    )
  }
  return (
    <div
      style={{
        position: "relative",
        margin: "0 auto",
        paddingTop: 40,
        maxWidth: 1000,
        minHeight: "100vh",
      }}
    >
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popPaper,
        }}
        open={open}
        anchorEl={anchorEl.target}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {anchorEl.name} : {anchorEl.amount}
      </Popover>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 className={classes.title}>Leaderboard<InfoIcon style={{marginLeft:20}} onClick={()=>{
        window.open("https://medium.com/@yggsea/announcing-the-ygg-sea-stake-and-reward-program-54a3af7203fc");
      }}/></h1>
        </Grid>
        <Grid item xs={12}>
        <Tabs
            tabIndex={tabValue}
            handleChange={handleTabChange}
            tabs={[
              {label:"Daily",content:renderBoard()},
              {label:"Overall",content:renderBoard()}
            ]}
          />
        </Grid>


       
      </Grid>
    </div>
  );
};

export default Leaderboard;
