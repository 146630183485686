import { stakingPoolABI } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";
import {
  FETCH_CLAIM_ALL_BEGIN,
  FETCH_CLAIM_ALL_SUCCESS,
} from "features/reward/redux/constants";
export const claimAll = async ({
  web3,
  address,
  contractAddress,
  dispatch,
}) => {
  const contract = new web3.eth.Contract(
    stakingPoolABI,
    contractAddress
  );
  const data = await _claimAll({
    web3,
    contract,
    address,
    dispatch,
  });
  return data;
};

const _claimAll = ({ web3, contract, address, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .claimAll(address)
      .send({ from: address })
      .on("transactionHash", function (hash) {
        dispatch({
          type: FETCH_CLAIM_ALL_BEGIN,
        });
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
            hash,
          })
        );
      })
      .on("receipt", function (receipt) {
        dispatch({
          type: FETCH_CLAIM_ALL_SUCCESS,
        });
        dispatch(
          enqueueSnackbar({
            message:"Claim All Success",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );
        resolve();
      })
      .on("error", function (error) {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
