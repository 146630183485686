import { grayColor } from "assets/jss/material-kit-pro-react.js";
const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    color: `white`,
    textTransform: "none",
    // border: `1px solid ${theme.palette.text.green}`,
    height: "36px",

    fontWeight: "bold",
    borderRadius: "8px",
    position: "relative",
    padding: "10px 10px",
    margin: 3,
    fontSize: "17px",
    letterSpacing: "0",
    // willChange: 'box-shadow, transform',
    // transition:
    //   'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    // touchAction: 'manipulation',
    cursor: "pointer",
    "& .MuiButton-label": {
      // color: `${theme.palette.text.green}`,
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  fullWidth: {
    width: "100%",
    padding:25,
    margin: 0,
  },
  blueOutlined: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.green}`,
    color: `${theme.palette.text.green}`,
    "& .MuiButton-label": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
  },
  blue: {
    boxShadow: "5px 5px 5px 1px rgba(0, 0, 0, 0.5)",
    minWidth: 180,
  },
  primary: {
    border: '1px solid',
    borderImageSlice: 1,
    borderWidth: 1,
    backgroundColor: "transparent",
    borderRadius: 2,
    borderImageSource: 'linear-gradient( 93.39deg, #d53f8c -5.31%, #d53f8c -5.3%, #f800ff 100% )',
    fontSize: 20,
    height: "48px",
    margin: '5px 0',
    "& .MuiButton-label": {
      color: "#d53f8c",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#d53f8c",
      },
      backgroundColor: "transparent",
    },
  },
  secondary: {
    background: 'linear-gradient(93.39deg, #d53f8c -5.31%, #d53f8c -5.3%, #F800FF 100%)',
    margin: '5px 0',
    minWidth: 180,
    fontSize: 20,
    borderRadius: "4px",
    height: 56,
    "& .MuiButton-label": {
      color: `white`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: 'linear-gradient(93.39deg, #d53f8c -5.31%, #d53f8c -5.3%, #F800FF 100%)',
    },
  },
  text: {
    border: `none`,
    fontSize: "0.95rem",
    backgroundColor: "transparents",
    fontWeight: 900,
    "& .MuiButton-label": {
      color: '#d53f8c'
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: '#d53f8c'
      },
      backgroundColor: "transparents",
    },
  },
  info: {
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "0px 20px",
    border: `1px solid ${theme.palette.text.primary}`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.primary,
      },
      backgroundColor: "transparent",
    },
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.65",
    textDecoration: "none",
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    border: "none",
    backgroundColor: "rgb(221, 221, 221)",

    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    height: "54px",
    padding: "1.125rem 2.25rem",
    fontSize: "1.3rem",
    lineHeight: "1.333333",
  },
  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px",
      },
    },
    padding: "0.3rem 0.5rem",
    height: 30,
    fontSize: "0.9rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: grayColor[0],
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "28px",
    fontSize: "20px",
    height: "56px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;
