import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { pools, tokens } from "features/configure";
import { useParams } from "react-router-dom";
import { convertAmountToNumber } from "features/helpers/bignumber";
import { convertAmountFromRawNumber } from "features/helpers/bignumber";
import _ from "lodash";
import {
  useFetchStakeDetail,
  useFetchApproval,
  useFetchStake,
} from "features/stake/redux/hooks";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useFetchPoolDetails,
} from "../home/redux/hooks";
import { useFetchPrice } from "../price/redux/hooks";
import Tabs from "components/CustomTabs/Tabs.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { Grid, Slider, Modal } from "@material-ui/core";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "30%",
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    padding: "15px 25px",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "white",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function Stake({poolName}) {

  const SECONDS_PER_YEAR = 86400 * 365;
  const poolIdForLp = 1;

  // const { poolName } = useParams();

  const data = _.find(pools, { stakedTokenName: poolName.toUpperCase() });
  const [depositBalance, setDepositBalance] = useState("");
  const { fetchStakeDetail, fetchStakeDetailPending, detail } =
    useFetchStakeDetail();

  const { fetchStake, fetchStakePending } = useFetchStake(data);

  const { fetchPrice, priceDatas, lpData, ethData } = useFetchPrice();

  const { poolDetails, fetchPoolDetails } = useFetchPoolDetails();

  const { web3, address } = useConnectWallet();
  const classes = useStyles();

  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const [lockedValue, setLockedValue] = useState(12);
  const [stakeAble, setStakeAble] = useState(false);
  const [poolTotalStaked, setPoolTotalStaked] = useState([]);
  const [poolTotalSupply, setPoolTotalSupply] = useState("");
  const [poolTotalValueLocked, setPoolTotalLocked] = useState([]);
  const [poolApr, setPoolApr] = useState([]);
  const [tabValue, setTabValue] = React.useState(1);

  
  const handleSliderChange = (event, newValue) => {
    setLockedValue(newValue);
  };

  useEffect(() => {
    if (web3 && address) {
      fetchStakeDetail({ web3, address, data });
      fetchPrice({ web3 });
      fetchPoolDetails();

      const id = setInterval(() => {
        fetchStakeDetail({ web3, address, data });
        fetchPrice({ web3 });
        fetchPoolDetails();

      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    let poolTotalStaked = [];
    let poolTotalSupply = [];
    let poolLength = poolDetails ? poolDetails.length : 0;
    for (let i = 0; i < poolLength; i++) {
      poolTotalStaked.push(poolDetails[i].totalStaked);
      poolTotalSupply.push(poolDetails[i].totalSupply);
    }
    setPoolTotalStaked(poolTotalStaked);
    setPoolTotalSupply(poolTotalSupply);
  }, [poolDetails]);

  useEffect(() => {
    const walletBalance = detail.balance ? detail.balance : 0;
    const hasZeroBalance = walletBalance == 0;
    setStakeAble(!Boolean(fetchStakePending || hasZeroBalance));
  }, [fetchStakePending, detail]);

  useEffect(() => {
    let poolTotalValueLocked = [];
    let poolApr = [];

    let poolTotalStakedLength = poolTotalStaked ? poolTotalStaked.length : 0;

    let singleTokenPrice = priceDatas ? priceDatas[tokens.basicRewardAddress] : 0;

    for (let i = 0; i < poolTotalStakedLength; i++) {
      let rewardValue = 0;
      for (let j = 0; j < tokens.rewardTokens.length; j++) {
        let rewardAddress = tokens.rewardTokens[j].address;
        let rewardPerSecond = convertAmountFromRawNumber(tokens.rewardTokens[j].rewardPerSecond);
        let price = priceDatas ? priceDatas[rewardAddress] : 0;
        rewardValue += rewardPerSecond*SECONDS_PER_YEAR*pools[i].weight*price
      }

      if (i === poolIdForLp) {
        let lpTotalSupply = lpData ? lpData.totalSupply : 0;
        let lpBaseReserve = lpData ? lpData.baseReserve : 0;
        let lpPrice = (lpBaseReserve * 2 * ethData) / lpTotalSupply;
        

        let apr =
          poolTotalStaked[i] * lpPrice < 10
            ? 0
            : (
                rewardValue *
                100) /
              (poolTotalSupply[i] * lpPrice);
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * lpPrice).toFixed(2)
        );

        poolApr.push(apr);
      } else {
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * singleTokenPrice).toFixed(2)
        );
        let apr =
          poolTotalStaked[i] * singleTokenPrice < 10
            ? 0
            : (rewardValue * 100) /
              (poolTotalSupply[i] * singleTokenPrice);
        poolApr.push(apr);
      }

      let avgLockedDay = parseFloat(
        (52 * 7 * (poolTotalSupply[i] - poolTotalStaked[i])) /
          poolTotalStaked[i]
      ).toFixed(2);


    }
    setPoolTotalLocked(poolTotalValueLocked);

    setPoolApr(poolApr);
  }, [poolTotalStaked, poolTotalSupply, priceDatas, lpData]);

  const setDepositMaximumInput = () => {
    const total = detail.balance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      setDepositBalance(amount.replaceAll(",", ""));
    }
  };
  const changeDepositInputValue = (event) => {
    let value = event.target.value;
    const total = detail.balance;
    if (!inputLimitPass(value)) {
      return;
    }

    setDepositBalance(inputFinalVal(value, total));
  };

  const onApproval = () => {
    fetchApproval({
      tokenAddress: data.tokenAddress,
      contractAddress: data.poolAddress,
    }).then(()=>{
      fetchStakeDetail({ web3, address, data });
    });
  };

  const onDeposit = () => {
    let amountValue = depositBalance
      ? depositBalance.replaceAll(",", "")
      : depositBalance;

    fetchStake({
      address,
      web3,
      poolData: data,
      duration: lockedValue,
      amount: convertAmountToNumber(amountValue),
    }).then(()=>{setDepositBalance("");});
    
  };

  function valuetext(value) {
    return `${value} weeks`;
  }
  const renderInputField = (isLocked) => {
    let boostweight = isLocked?((1 + 3*(lockedValue) / 12).toFixed(2)) : 1;
    return (
      <div>
        
        {isLocked && (
          <div>
            <Grid container>
              <Grid item xs={6}>
                Locked for: {lockedValue} months
              </Grid>
              <Grid item xs={6}>
                weight: {(1 + 3*(lockedValue) / 12).toFixed(2)}
              </Grid>
            </Grid>
            <PrettoSlider
              min={1}
              max={12}
              style={{ height: 20 }}
              defaultValue={12}
              value={typeof lockedValue === "number" ? lockedValue : 0}
              onChange={handleSliderChange}
              step={null}
              marks={[{value:1},{value:3},{value:6},{value:12}]}
            />
          </div>
        )}
        <CustomOutlinedInput
          value={depositBalance}
          onClick={() => {}}
          availabletext={`Wallet: ${_.get(detail, "balance", "-")} ${poolName}`}
          onChange={changeDepositInputValue}
          setMax={() => setDepositMaximumInput()}
        />
        <Grid container style={{marginTop:20}}>
          <Grid item xs={6} className="cardTitle">
          Est APR :
          </Grid>
          <Grid item xs={6} className="cardValue">
            {`${poolName.toUpperCase() == 'SEA' ? (boostweight*(poolApr[0])).toFixed(0):(boostweight*poolApr[1]).toFixed(0)}%`}
          </Grid>
        </Grid>

        <div style={{ marginTop: 100 }}>
          <div className="alertCard">
            By staking your SEA tokens to the SEA Pool, you are assuming all responsibility with the risks associated with staking contracts.
          </div>
          {detail && parseFloat(detail.allowance) > 0 ? (
            <Button
              disabled={!stakeAble}
              onClick={() => {
                onDeposit();
              }}
              color="secondary"
              fullWidth
            >
              Stake
            </Button>
          ) : (
            <Button
              fullWidth
              disabled={fetchApprovalPending}
              onClick={() => {
                onApproval();
              }}
              color="secondary"
            >
              Approve
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={{ }}>
       <Modal
        className={classes.modal}
        open={fetchStakePending || fetchApprovalPending}>
          <div className={classes.paper} 
          style={{ height:120, textAlign:"center"}}>
            <CircularProgress/>
            <h2>Loading...</h2>
          </div>
        </Modal>
      <Grid container spacing={1} style={{maxWidth:600, margin: "0 auto"}}>
        <Grid item xs={12} sm={12}>
          <div className="card">
            <div className="header">Stake {data.stakedTokenName}</div>
            <div className="subHeader"></div>
            <div className="card-black" style={{ maxWidth: 650, margin: "0 auto" }}>
            {renderInputField(true)}
          </div>
        </div>
        </Grid>
    
      </Grid>
     
    </div>
  );
}
