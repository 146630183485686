import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid,Modal } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useConnectWallet } from "features/home/redux/hooks";
import Stake from "features/stake/Stake";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { pools, tokens } from "features/configure";
import {
  useFetchDashboard,
  useFetchPoolDetails,
  useFetchRewardPoolsDetails,
  useFetchWithdraw,
} from "./redux/hooks";
import {
  useFetchEscrowDetail,
 
} from "../reward/redux/hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFetchPrice } from "../price/redux/hooks";
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import CustomTable from "components/CustomTable/CustomTable.js";
import _ from "lodash";
import moment from "moment";
import PriceChart from "./components/PriceChart"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: 400,
    textAlign: "left",
    color: "white",
    fontSize: 32,
    lineHeight: 1.1,
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  paper: {
    width: "30%",
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    padding: "15px 25px",
  },
  modalPaper:{
    minWidth: 300,
    textAlign:"center",
    color:"white",
    backgroundColor: "#080B1D",
    padding: "55px 25px",
  }
}));

const Overview = () => {
  const classes = useStyles();
  const theme = useTheme();
  const SECONDS_PER_YEAR = 86400 * 365;
  const MAX_LOCKED_BOOSTED = 4;

  const data = _.find(pools, { pid: 0 });

  const { fetchDashboard, detail } = useFetchDashboard();
  const { fetchPrice, priceDatas, lpData, ethData } = useFetchPrice();

  const { poolDetails, fetchPoolDetails } = useFetchPoolDetails();
  const { rewardPoolsDetails, fetchRewardPoolsDetails } =
    useFetchRewardPoolsDetails();
  const { fetchEscrowDetail, escrowDetails } = useFetchEscrowDetail();
    
  const { fetchWithdraw } = useFetchWithdraw(data);

  const [baseRewardPrice, setBaseRewardPrice] = useState(0);
  const [userStaked, setUserStaked] = useState("");
  const [userUnclaimedRewards, setUserUnclaimedRewards] = useState("");
  const [userEstRewards, setUserEstRewards] = useState("");
  const [userDeposits, setDeposits] = useState([]);
  const [totalStaked, setTotalStaked] = useState("");
  const [totalValueLocked, setTotalValueLocked] = useState("");

  const [totalValuedClaimed, setTotalValueClaimed] = useState("");
  const [poolTotalStaked, setPoolTotalStaked] = useState([]);
  const [poolTotalSupply, setPoolTotalSupply] = useState("");
  const [poolAvgLockedDay, setPoolAvgLockedDay] = useState([]);
  const [poolApr, setPoolApr] = useState([]);
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [poolTotalValueLocked, setPoolTotalLocked] = useState([]);
  const [circulatingSupplyStaked, setCirculatingSupplyStaked] = useState("");
  const [open, setOpen] = React.useState(false);
  const [poolName, setPoolName] = React.useState(pools[0].stakedTokenName);
  const poolIdForLp = 1;
  const [alert, setAlert] = useState(false);
  const [agree, setAgree] = useState(false);

  const { web3, address } = useConnectWallet();
  const handleOpen = (name) => {
    if(agree){
      setPoolName(name)
      setOpen(true);
    }else{
      setPoolName(name)
      setAlert(true)
    }
      
  
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onWithdraw = (pooldId, depositId) => {
    fetchWithdraw({
      address,
      web3,
      depositId,
      poolData: _.find(pools, { pid: pooldId }),
    });
  };

  useEffect(() => {
    if (web3 && address) {
      fetchDashboard({ web3, address });
      fetchPrice({ web3 });
      fetchPoolDetails();
      fetchRewardPoolsDetails();
      fetchEscrowDetail({ web3, address });
      const id = setInterval(() => {
        fetchDashboard({ web3, address });
        fetchPrice({ web3 });
        fetchPoolDetails();
        fetchRewardPoolsDetails();
        fetchEscrowDetail({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    let staked = detail["pools"]
      ? _.reduce(
        detail["pools"],
          function (sum, n, index) {
            let lpTotalSupply = lpData ? lpData.totalSupply : 0;
            let lpBaseReserve = lpData ? lpData.baseReserve : 0;
            let lpPrice = (lpBaseReserve * 2 * ethData) / lpTotalSupply;

            let singleTokenPrice = priceDatas ? priceDatas[tokens.basicRewardAddress] : 0;
            let sPrice = index == 0 ? singleTokenPrice : lpPrice;

            return sum + n.accountTotalDeposit * sPrice;
          },
          0
        )
      : "N/A";
    setUserStaked(staked);
    setBaseRewardPrice(priceDatas ? priceDatas[tokens.basicRewardAddress] : 0)
  }, [detail, priceDatas]);

  useEffect(() => {
    
    let poolLength = detail["pools"] ? detail["pools"].length : 0;
    let unclaimed = 0;
    let userEstReward = 0;
    for (let i = 0; i < poolLength; i++) {
      
      userEstReward = (parseFloat(detail["pools"][i].accountEstRewards) * baseRewardPrice).toFixed(2);

      userEstReward = isNaN(userEstReward)?0:userEstReward;

      let pendingRewardsLength = detail["pools"][i].accountPendingRewards.length;
      for (let j = 0; j < pendingRewardsLength; j++) {
        let pendingRewards = detail["pools"][i].accountPendingRewards[j].amount
        let tokenAddress = detail["pools"][i].accountPendingRewards[j].tokenAddress
        let price = priceDatas ? priceDatas[tokenAddress.toLowerCase()] : 0;
        unclaimed = unclaimed + pendingRewards * price;
      }
    }

    setUserUnclaimedRewards(unclaimed);
    setUserEstRewards(userEstReward);
  }, [detail, priceDatas]);

  useEffect(() => {
    let poolTotalStaked = [];
    let poolTotalSupply = [];
    let poolLength = poolDetails ? poolDetails.length : 0;
    for (let i = 0; i < poolLength; i++) {
      poolTotalStaked.push(poolDetails[i].totalStaked);
      poolTotalSupply.push(poolDetails[i].totalSupply);
    }
    setPoolTotalStaked(poolTotalStaked);
    setPoolTotalSupply(poolTotalSupply);
  }, [poolDetails, priceDatas]);

  useEffect(() => {
    let totalStaked = 0;
    let poolTotalStakedLength = poolTotalStaked ? poolTotalStaked.length : 0;
    for (let i = 0; i < poolTotalStakedLength; i++) {
      if (i === poolIdForLp) {
        let lpTotalSupply = lpData ? lpData.totalSupply : 0;
        let lpTokenReserve = lpData ? lpData.tokenReserve : 0;
        totalStaked =
          parseFloat((poolTotalStaked[i] / lpTotalSupply) * lpTokenReserve) +
          parseFloat(totalStaked);
      } else {
        totalStaked = parseFloat(poolTotalStaked[i]) + parseFloat(totalStaked);
      }
    }
    let circulatingSupplyStaked = new BigNumber(totalStaked)
      .dividedBy(new BigNumber(75000000))
      .multipliedBy(new BigNumber(100))
      .toFormat(4);
    setTotalStaked(totalStaked);
    setCirculatingSupplyStaked(circulatingSupplyStaked);
  }, [poolTotalStaked, priceDatas, lpData]);

  useEffect(() => {
    let poolTotalValueLocked = [];
    let poolAvgLockedDay = [];
    let poolApr = [];

    let poolTotalStakedLength = poolTotalStaked ? poolTotalStaked.length : 0;
    let singleTokenPrice = priceDatas ? priceDatas[tokens.basicRewardAddress] : 0;

    for (let i = 0; i < poolTotalStakedLength; i++) {

      let rewardValue = 0;
      for (let j = 0; j < tokens.rewardTokens.length; j++) {
        let rewardAddress = tokens.rewardTokens[j].address;
        let rewardPerSecond = convertAmountFromRawNumber(tokens.rewardTokens[j].rewardPerSecond);
        let price = priceDatas ? priceDatas[rewardAddress] : 0;
        rewardValue += rewardPerSecond*SECONDS_PER_YEAR*pools[i].weight*price
      }

      if (i === poolIdForLp) {
        let lpTotalSupply = lpData ? lpData.totalSupply : 0;
        let lpBaseReserve = lpData ? lpData.baseReserve : 0;
        let lpPrice = (lpBaseReserve * 2 * ethData) / lpTotalSupply;

        let apr =
          poolTotalStaked[i] * lpPrice < 10
            ? 0
            : (
              rewardValue * 100) /
              (poolTotalSupply[i] * lpPrice);
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * lpPrice).toFixed(2)
        );
        poolApr.push(apr);
      } else {
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * singleTokenPrice).toFixed(2)
        );
        let apr =
          poolTotalStaked[i] * singleTokenPrice < 10
            ? 0
            : (rewardValue * 100) /
              (poolTotalSupply[i] * singleTokenPrice);
        poolApr.push(apr);
      }

      let avgLockedDay = parseFloat(
        (52 * 7 * (poolTotalSupply[i] - poolTotalStaked[i])) /
          (poolTotalStaked[i]*3)
      ).toFixed(2);

      if (avgLockedDay > 0) {
        poolAvgLockedDay.push(
          parseFloat(
            (52 * 7 * (poolTotalSupply[i] - poolTotalStaked[i])) /
              (poolTotalStaked[i]*3)
          ).toFixed(2)
        );
      } else {
        poolAvgLockedDay.push(parseFloat(0).toFixed(2));
      }
    }
    setPoolTotalLocked(poolTotalValueLocked);
    setPoolAvgLockedDay(poolAvgLockedDay);
    setPoolApr(poolApr);
  }, [poolTotalStaked, poolTotalSupply, priceDatas, lpData]);

  useEffect(() => {
    let totalValueLocked = 0;
    let poolTotalValueLockedLength = poolTotalValueLocked
      ? poolTotalValueLocked.length
      : 0;
    for (let i = 0; i < poolTotalValueLockedLength; i++) {
      totalValueLocked =
        parseFloat(poolTotalValueLocked[i]) + parseFloat(totalValueLocked);
    }
    setTotalValueLocked(totalValueLocked);
  }, [poolTotalValueLocked, priceDatas]);

  useEffect(() => {
    let totalValueClaimed = 0;
    let rewardPoolLength = rewardPoolsDetails
      ? rewardPoolsDetails.length
      : 0;
    for (let i = 0; i< rewardPoolLength; i++) {
      let rewardPoolDetails = rewardPoolsDetails[i];
      let price = priceDatas ? priceDatas[rewardPoolDetails.tokenAddress] : 0;
      let poolTotalValueClaimed = convertAmountFromRawNumber(rewardPoolDetails.balance) * price;
      totalValueClaimed = totalValueClaimed + poolTotalValueClaimed
    }
    setTotalValueClaimed(totalValueClaimed);
  }, [rewardPoolsDetails, priceDatas]);

  useEffect(() => {
    let deposits = [];
    let poolLength = detail["pools"] ? detail["pools"].length : 0;
    for (let i = 0; i < poolLength; i++) {
      let depositsLength = detail["pools"][i].deposits.length;
      for (let j = 0; j < depositsLength; j++) {
        let userDeposit = {
          poolId: i,
          amount: detail["pools"][i].deposits[j][0],
          start: detail["pools"][i].deposits[j][1],
          end: detail["pools"][i].deposits[j][2],
          depositId: j,
        };
        deposits.push(userDeposit);
      }
    }
    setDeposits(deposits);
  }, [detail]);

  return (
    <>
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          paddingTop: 40,
          maxWidth: 1100,
          minHeight: "100vh",
        }}
      >
     <Modal
      className={classes.modal}
      open={alert}
      onClose={()=>{
        setAlert(false)
      }}>
        <div className={classes.modalPaper} >
          <ErrorOutlineIcon style={{fontSize:60}}/>
          <h1>Attention</h1>
          <div style={{margin:"40px 0", fontSize:16}}>
          DYOR before interacting with any staking contracts.<br/>
          Audits: 
          <a href="https://gateway.pinata.cloud/ipfs/QmXyKsEeDe1WzDEuT6v95oUdzVLr2B2xRiV51EamsizroV?preview=1" target={"_blank"}>Peckshield</a>, 
          <a href="https://gateway.pinata.cloud/ipfs/QmZLyPfGXNk4nsBKuSYeMndisV6mm3L91bX8TngLQzttGz?preview=1" target={"_blank"}>Certik</a>, and 
          <a href="https://certificate.quantstamp.com/full/vault-inc" target={"_blank"}>Quantstamp</a>
          </div>
          <Button color="secondary" onClick={()=>{
            setAgree(true)
            setAlert(false)
            setOpen(true)
            }} 
           >Yes, I understand the risk. </Button>
        </div>
      </Modal>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}>
          <div className="paper">
            <Stake poolName={poolName}/>
          </div>
      </Modal>
        
      <Grid container>
          <Grid item xs={12} sm={12} style={{textAlign:"center",color:"white", margin:"50px auto"}}>
              <div style={{fontSize:16, letterSpacing:5}}>YGGSEA STAKING</div>
              <div style={{fontSize:48 ,fontFamily:"Prompt"}}>Stake your <span style={{color:"#d53f8c"}}>SEA</span> Tokens,<br/>
                  Earn Rewards!</div>
             
         </Grid>
          <Grid item xs={12} sm={12}>
            <h1 className={classes.title}>Overview</h1>
          </Grid>
            <>
              <Grid item xs={12} sm={6}>
                <div className="card">
                <div style={{float:"right"}}>{(userStaked/baseRewardPrice).toFixed(2)} SEA <img src={require("assets/img/sea.png").default} className="smIcon"/></div>
                  <div className="cardSubHeader">YOU STAKED</div>{" "}
                  <div style={{textAlign:"center", margin:20}}>
                    <div className="cardTitle">Value</div>
                    <div className="cardLgValue">
                      {isNaN(userStaked)
                        ? "Loading..."
                        : "$" + parseFloat(userStaked).toFixed(2)}
                    </div>
                  </div>
                 
                  <div>
                    {address ? (
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          window.scrollTo({
                            top: computer ? 1400 : 2200,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Stake
                      </Button>
                    ) : (
                      <ConnectWallet />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card">
                  <div style={{float:"right"}}>{isNaN(((parseFloat(userUnclaimedRewards)+parseFloat(userEstRewards))/baseRewardPrice))?"-":((parseFloat(userUnclaimedRewards)+parseFloat(userEstRewards))/baseRewardPrice).toFixed(2)} SEA <img src={require("assets/img/sea.png").default} className="smIcon"/></div>
                  <div className="cardSubHeader">REWARDS</div>
                  <div style={{ margin:20}}>
                    <div style={{width:"50%", display:'inline-table'}}>
                    <div className="cardTitle">Estimated Rewards</div>
                    <div className="cardLgValue">
                      {isNaN(userEstRewards)
                        ? "Loading..."
                        : "$" + parseFloat(userEstRewards).toFixed(2)}
                    </div>
                    </div>
                    <div style={{width:"50%", display:'inline-table'}}>
                    <div className="cardTitle">Available to claim</div>
                    <div className="cardLgValue">
                      {isNaN(userUnclaimedRewards)
                        ? "Loading..."
                        : "$" + parseFloat(userUnclaimedRewards).toFixed(2)}
                    </div>
                    </div>
                
                  </div>
                  
                  <div>
                    {address ? (
                      <Button component={Link} to={"/reward"} color="secondary"
                      fullWidth>
                        Claim
                      </Button>
                    ) : (
                      <ConnectWallet />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card">
                  <div className="cardSubHeader">TOTAL AMOUNT STAKED</div>
                  <div className="cardLgValue">
                    $ {new BigNumber(parseFloat(totalValueLocked)).toFormat(2)}
                  </div>
                  <div className="hr"/>
                  <div className="cardSubHeader">TOTAL REWARDS CLAIMED</div>
                  <div className="cardLgValue">
                    ${" "}
                    {new BigNumber(parseFloat(totalValuedClaimed)).toFormat(2)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
              <div className="card">
                  <div className="cardSubHeader">LATEST STAKE START DATE</div>
                  <div className="cardLgValue">
                    {_.last(userDeposits)? moment(new Date(_.last(userDeposits).start * 1000)).format(
                          "YYYY/MM/DD"
                        ):"N/A"}
                  </div>
                  <div className="hr"/>
                  <div style={{width:"50%", display:'inline-table'}}>
                    <div className="cardSubHeader">CLAIM COUNT</div>
                    <div className="cardLgValue">
                      {escrowDetails.escrowedDatas?escrowDetails.escrowedDatas[0].deposits.length:0}
                    </div>
                  </div>
                  <div style={{width:"50%", display:'inline-table'}}>
                    <div className="cardSubHeader">NO. OF STAKES</div>
                    <div className="cardLgValue">
                      {userDeposits?userDeposits.length:0}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card" >
                  <div className="cardSubHeader">SEA PRICE</div>
                  <PriceChart label={"SEA"}/>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
              <div className="card">
                  <div className="cardSubHeader">CIRCULATING SUPPLY STAKED</div>
                  <div className="cardLgValue">
                    {new BigNumber(parseFloat(circulatingSupplyStaked)).toFormat(2)} %
                  </div>
                  <div className="hr"/>
                  <div className="cardSubHeader">AVERAGE LOCKED DAY</div>
                  <div className="cardLgValue">
                    {new BigNumber(parseFloat(poolAvgLockedDay[0])).toFormat(2)} day
                  </div>
                </div>
              </Grid>
        
              
            

              <Grid item xs={12}>
                <h1 className={classes.title}>Pools</h1>
              </Grid>
                
                 {pools.map((row, index) => {
                      return (<Grid item item xs={12} sm={6} >
                        <div className="card">

                        
                        <h1>{row.stakedTokenName}</h1>
                        <Grid container>
                          <Grid item xs={6} sm={6}  className="cardTitle">
                            Total Value Locked
                          </Grid>
                          <Grid item xs={6} sm={6} className="cardValue">
                            {poolTotalValueLocked[index]
                          ? `$ ${new BigNumber(
                              poolTotalValueLocked[index]
                            ).toFormat(0)}`
                          : "Loading..."}
                          </Grid>
                          <Grid item xs={6} className="cardTitle">
                            APR
                          </Grid>
                          <Grid item xs={6} className="cardValue">
                            {poolApr[index] > 0
                          ? `${new BigNumber(poolApr[index]).toFormat(0)} % - ${new BigNumber(MAX_LOCKED_BOOSTED * poolApr[index]).toFormat(0)} %`
                          : "-"}
                          </Grid>
                          <Grid item xs={12} className="hr"/>
                          <Grid item xs={6} className="cardTitle">
                          My Deposits
                          </Grid>
                          <Grid item xs={6} className="cardValue">
                            {userStaked
                          ? `$ ${new BigNumber(
                            userStaked
                            ).toFormat(0)}`
                          : "-"}
                          </Grid>
                          <Grid item xs={6} className="cardTitle">
                          Weight
                          </Grid>
                          <Grid item xs={6} className="cardValue">
                            {poolApr[index] > 0
                          ? row.weight *100 + "%"
                          : "-"}
                          </Grid>
                          <Grid item xs={6} className="cardTitle">
                            Estimated Rewards
                          </Grid>
                          <Grid item xs={6} className="cardValue">
                          {userEstRewards&&baseRewardPrice
                          ? (userEstRewards/baseRewardPrice).toFixed(2) + " SEA"
                          : "-"}
                          </Grid>
                        </Grid>
                        <div style={{height:100}}></div>
                          <Button
                            color="secondary"
                            fullWidth
                            onClick={()=>{handleOpen(row.stakedTokenName)}}
                            disabled
                          >
                            Stake
                          </Button>
                          <Button
                            color="primary"
                            fullWidth
                            onClick={() => window.open(row.getUrl)}
                            disabled
                          >
                            {index == 0 ? "Buy" : "Add"} {row.stakedTokenSymbol}
                          </Button>
                          
                      </div>
                      </Grid>
                 )})}
              <Grid xs={12} item style={{ marginBottom: 20 }}>
                <h1 className={classes.title}>Deposits</h1>
                <div className="card" style={{ marginTop: 40 }}>
                  <CustomTable
                    leftText={{}}
                    headers={[
                      "Pool",
                      `Amount Staked`,
                      "Lock Date",
                      "Unlock Date",
                      "Action",
                    ]}
                    contents={userDeposits.map((row) => {
                      return [
                        pools[row.poolId].stakedTokenName,
                        Number(convertAmountFromRawNumber(row.amount)).toFixed(
                          pools[row.poolId].toFixed
                        ),
                        moment(new Date(row.start * 1000)).format(
                          "YYYY/MM/DD, HH:mm:ss"
                        ),
                        moment(new Date(row.end * 1000)).format(
                          "YYYY/MM/DD, HH:mm:ss"
                        ),
                        <Button
                          color="secondary"
                          onClick={() => {
                            onWithdraw(row.poolId, row.depositId);
                          }}
                          disabled={
                            !moment(new Date()).isAfter(
                              moment(new Date(row.end * 1000))
                            )
                          }
                        >
                          Unlock
                        </Button>,
                      ];
                    })}
                  />
                </div>
              </Grid>
            </>
          
        </Grid>
      </div>
    </>
  );
};

export default Overview;
