import initialState from "./initialState";
import { reducer as fetchSwapDetailReducer } from "./fetchSwapDetail";
import { reducer as fetchSwapReducer } from "./fetchSwap";
import { reducer as fetchApprovalReducer } from "./fetchApproval";
const reducers = [fetchSwapDetailReducer,fetchApprovalReducer,fetchSwapReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
