import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { uniswapV2PairABI, contracts, tokens, liquidityBootstrappingPoolABI, balancerVaultABI, } from 'features/configure';
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import _ from "lodash";

export function fetchPrice({ web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {

    const copperLaunchPoolContract = new web3.eth.Contract(liquidityBootstrappingPoolABI, contracts.copperLaunchPoolContract.address);
    const balancerVaulContract = new web3.eth.Contract(balancerVaultABI, contracts.balancerVault.address);  

    const uniswapPairContract = new web3.eth.Contract(uniswapV2PairABI, contracts.uniswapLpPairAddress.address);

      let promises = [];

      promises.push(copperLaunchPoolContract.methods.getNormalizedWeights().call());
      promises.push(balancerVaulContract.methods.getPoolTokens(contracts.balancerVault.poolId).call());
      for (let rewardToken of tokens.rewardTokens) {
        promises.push(axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: rewardToken.coingeckoId, vs_currencies: 'usd' },
        }))
      }

      // promises.push(uniswapPairContract.methods.getReserves().call())
      // promises.push(uniswapPairContract.methods.totalSupply().call())
      promises.push(axios.get(`https://analytics.vault.inc/api/stats/priceHistory?name=yggsea`));
      promises.push(axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: "ethereum", vs_currencies: 'usd' },
        }))

      Promise.all(promises).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceDatas, lpData, chart, ethData } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceDatas: state.price.priceDatas, 
    lpData: state.price.lpData,
    chart: state.price.chart,
    ethData: state.price.ethData,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceDatas, 
    lpData,
    ethData,
    chart
  };
}

export function reducer(state = { fetchPricePending: false, priceDatas: {}, lpData: {},chart:[] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:

      let priceDatas = {};

      const tokenWeight = convertAmountFromRawNumber(action.data[0][1]);
      const usdcWeight = convertAmountFromRawNumber(action.data[0][0]);
      const tokenBalances = convertAmountFromRawNumber(action.data[1].balances[1]);
      const usdcBalances = convertAmountFromRawNumber(action.data[1].balances[0], 6);

      tokens.rewardTokens.forEach(function (reward, j) {
        // const price = tokenWeight*usdcBalances/(usdcWeight*tokenBalances);;
        let price = 0;
        priceDatas[reward.address] = price
      });

      let basicRewardPrice = priceDatas[tokens.basicRewardAddress];

      // let chart = action.data[3].data;
      let chart = [[
        1651968000000,
        0
      ],
      [
        1652054400000,
        0
      ],
      [
        1652140800000,
        0
      ]]


      let latest = _.last(chart);
      
      let modifiedLatest = (_.dropRight(latest));
      modifiedLatest.push(basicRewardPrice);
      
      let modifiedChart = (_.dropRight(chart));
      modifiedChart.push(modifiedLatest);

      const ethData = action.data[4].data['ethereum'].usd;

      const lpData = {
        // tokenReserve: convertAmountFromRawNumber(action.data[3]._reserve0),
        // baseReserve: convertAmountFromRawNumber(action.data[3]._reserve1),
        // totalSupply: convertAmountFromRawNumber(action.data[4])
      }
      return {
        ...state,
        priceDatas: priceDatas,
        chart:modifiedChart,
        lpData: lpData,
        ethData: ethData,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
