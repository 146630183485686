export const tokens = {
  basicRewardAddress: "0xff9fea2666ac0b074a1a53579ff95add4df0601a",
  rewardTokens: [
    {
      token: "SEA",
      address: "0xff9fea2666ac0b074a1a53579ff95add4df0601a",
      decimals: 18,
      coingeckoId: "breederdao", //TODO
      rewardPerSecond: 964506172839506000
    }
  ]
};