import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Modal } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { pools } from "features/configure";
import { useFetchRewardsDetail, useFetchClaimReward, useFetchClaimAll, useFetchEscrowDetail } from "./redux/hooks";
import { useFetchPrice } from "../price/redux/hooks";
import {
  convertAmountFromRawNumber,
} from "features/helpers/bignumber";
import { formatCountdown } from "features/helpers/format";
import CustomTable from "components/CustomTable/CustomTable.js";
import Tabs from "components/CustomTabs/Tabs.js";
import _ from "lodash";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.1,
    color: "white",
  },
  subTitle: {
    fontSize: 30,
    lineHeight: 1.1,
    color: "white",
    padding: "8px"
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    minWidth: 250,
    height:120, 
    textAlign:"center",
    backgroundColor: theme.palette.background.paper,
    padding: "15px 25px",
  },
}));

const Reward = () => {
  const classes = useStyles();
  const { fetchRewardsDetail, detail } = useFetchRewardsDetail();
  const { fetchEscrowDetail, escrowDetails } = useFetchEscrowDetail();
  const { fetchClaimReward , fetchClaimRewardPending} = useFetchClaimReward();
  const { fetchClaimAll, fetchClaimAllPending} = useFetchClaimAll();
  const { fetchPrice, priceDatas } = useFetchPrice();

  const { web3, address } = useConnectWallet();
  const [now, setNow] = useState(moment());
  const escrowedDatas = escrowDetails.escrowedDatas ? escrowDetails.escrowedDatas : [];

  const [poolRewards, setPoolRewards] = useState([]);
  const [poolHasClaimableRewards, setPoolHasClaimableRewards] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  let deadline = moment().set({
    hour: 11,
    minute: 45,
    second: 0,
    millisecond: 0,
  });
  if (now > deadline) {
    deadline = deadline.add(1, "days");
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(moment());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [now]);
  let countdown = formatCountdown(deadline, now);

  const onClaim = (pid, rewardAddress) => {
    fetchClaimReward({
      address,
      web3,
      contractAddress: pools[pid].poolAddress,
      rewardAddress: rewardAddress
    }).then(() => {});
  };

  const onClaimAll = (pid) => {
    fetchClaimAll({
      address,
      web3,
      contractAddress: pools[pid].poolAddress
    }).then(() => {});
  };

  useEffect(() => {
    if (web3 && address) {
      fetchRewardsDetail({ web3, address });
      fetchEscrowDetail({ web3, address });
      fetchPrice({ web3 });
      const id = setInterval(() => {
        fetchRewardsDetail({ web3, address });
        fetchEscrowDetail({ web3, address });
        fetchPrice({ web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);


  useEffect(() => {
    let poolRewards = []
    let poolHasClaimableRewards = []
    for (let pool of pools) {
      let pid = pool.pid
      let rewardsLength = detail[pid] ? (detail[pid].claimableTokenRewards ? detail[pid].claimableTokenRewards.length : 0) : 0;
      const singlePoolRewards = []
      let singlePoolHasClaimableRewards = false;
      for (let i = 0; i < rewardsLength; i++) {
        const poolReward = {
          pid: pool.pid,
          stakedToken: detail[pool.pid].stakedToken,
          claimableTokenReward: detail[pid].claimableTokenRewards[i].amount,
          reward: detail[pid].claimableTokenRewards[i].token,
          rewardName: detail[pid].claimableTokenRewards[i].name
        }
        if (detail[pid].claimableTokenRewards[i].amount > 0) {
          singlePoolHasClaimableRewards = true
        }
        singlePoolRewards.push(poolReward)
      }
      poolHasClaimableRewards.push(singlePoolHasClaimableRewards);
      poolRewards.push(singlePoolRewards);
    }
    setPoolRewards(poolRewards);
    setPoolHasClaimableRewards(poolHasClaimableRewards);
  }, [detail]);

  return (
    <div
      style={{
        position: "relative",
        margin: "0 auto",
        paddingTop: 40,
        maxWidth: 1000,
        minHeight: "100vh",
      }}
    >
      <Modal
        className={classes.modal}
        open={fetchClaimRewardPending || fetchClaimAllPending}>
          <div className={classes.modalPaper}>
            <CircularProgress/>
            <h2>Loading...</h2>
          </div>
        </Modal>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <h1 className={classes.title}>Rewards</h1>
        </Grid>
        <Grid item xs={12} sm={6} style={{ fontSize: 18, lineHeight: 1.5 }}>
          {`Staking rewards enter a 6 month vesting period after claiming. sAssets
          are non-transferable and only used for accounting purposes.`}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ fontSize: 14, lineHeight: 1.5 }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            fontSize: 14,
            lineHeight: 1.5,
            textAlign: "right",
            color: "white",
          }}
        >
          Next rewards released in {countdown.hours} : {countdown.minutes}{" "}
          : {countdown.seconds}
        </Grid>
        {_.map(pools, (pool, index) => {
          let singlePoolRewards = poolRewards[pool.pid] ? poolRewards[pool.pid] : []
          return (<Grid xs={12} >
            <h2 className={classes.subTitle}>{pool.stakedTokenName} Pool</h2>
            <div className="card">
              <CustomTable
                leftText={{}}
                headers={[
                  "Core Pools",
                  "Amount Staked",
                  "Reward Token",
                  "Claimable Rewards",
                  "Action",
                ]}
                contents={singlePoolRewards.map((poolReward, poolRewardIndex) => {
                  if (poolRewardIndex == 0) {
                    return [
                      pools[poolReward.pid].stakedTokenName,
                      Number(poolReward.stakedToken, 0).toFixed(pools[poolReward.pid].toFixed),
                      poolReward.rewardName,
                      Number(poolReward.claimableTokenReward, 0).toFixed(2),
                      <Button
                        color="secondary"
                        onClick={() => {
                          onClaim(poolReward.pid, poolReward.reward);
                        }}
                        disabled={poolReward.claimableTokenReward == 0}
                      >
                        Claim
                      </Button>
                    ];
                  } else {
                    return [
                      "",
                      "",
                      poolReward.rewardName,
                      Number(poolReward.claimableTokenReward, 0).toFixed(2),
                      <Button
                        color="secondary"
                        onClick={() => {
                          onClaim(poolReward.pid, poolReward.reward);
                        }}
                        disabled={poolReward.claimableTokenReward == 0}
                      >
                        Claim
                      </Button>
                    ];
                  }
                })}
              />
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  onClaimAll(pool.pid);
                }}
                disabled={!poolHasClaimableRewards[index]}
              >
                Claim All
              </Button>
            </div>
          </Grid>)
        }
          
        )}
        <Grid xs={12}>
          <h1 className={classes.title}>Locked Rewards</h1>
          <Tabs
            tabIndex={tabValue}
            handleChange={handleTabChange}
            tabs={ escrowedDatas ?_.map(escrowedDatas, (escrowData, index) => {
              return {label:escrowData.tokenName, content:(
                <>
                  <div className="card" style={{ marginTop: 40, marginBottom: 40 }}>
                    <h2>Total Amount: {Number(
                          convertAmountFromRawNumber(_.reduce(escrowData.deposits, function(sum, row) {
                            return sum + Number(
                              convertAmountFromRawNumber(row[0])
                            );
                          }, 0))
                        ).toFixed(2)}</h2>
                    <CustomTable
                      leftText={{}}
                      headers={[
                        `Amount`,
                        "Dollar Value",
                        "Status",
                        "Time Remaining",
                      ]}
                      contents={escrowData.deposits.map((row, index) => {
                        const endTime = new Date(row[2] * 1000);
                        const now = new Date();
                        const timeDiff = moment(endTime) - moment(now);
                        const dur = moment.duration(timeDiff);
                        const amount = Number(
                          convertAmountFromRawNumber(row[0])
                        ).toFixed(2);
                        const price = priceDatas ? priceDatas[escrowData.tokenAddress] : 0;
  
                        const value = (amount * parseFloat(price)).toFixed(2);
                        const status = timeDiff > 0 ? "Locked" : "Unlocked";
  
                        return [
                          amount,
                          `$${value}`,
                          status,
                          `${dur.months()} months ${dur.days()} days ${dur.hours()} hrs ${dur.minutes()} mins`,
                        ];
                      })}
                    />
                  </div>
                </>
                
              )}}
            ):[]}
          />
         
        </Grid>
      </Grid>
    </div>
  );
};

export default Reward;
