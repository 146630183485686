export const pools = [
  {
    pid: 0,
    stakedTokenName: "SEA",
    stakedTokenSymbol: "SEA",
    rewardTokenName:"SEA",
    stakedTokenDecimals: 18,
    tokenAddress: "0xff9fea2666ac0b074a1a53579ff95add4df0601a",
    poolAddress: "0xC98F0fB7B729aF60E6631E0F8837Bd8c7E75efA6",
    getUrl:"https://copperlaunch.com/pools/0x55eBdd8e500e7b122e01eBf0b516C536280B2a1B",
    weight: 1,
    toFixed: 2
  }
];
