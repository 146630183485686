import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Notifier } from "features/common";
import appStyle from "./jss/appStyle.js";
import createAppTheme from "./jss/appTheme";
import Background from "assets/img/background.png";
import LandingHeader from "./components/LandingHeader";
const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = createAppTheme(true);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
      <div style={{backgroundColor:"red", fontSize:18, textAlign:"center",color:"white"}}>We are temporarily postponing YGG SEA's IDO on Copper, <a href="https://twitter.com/yggsea/status/1523992624482631681" target="_blank">please find more details here</a></div>
        <div
          style={{
            padding: 10,
            background: `url(${Background})`,
            backgroundSize:"cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          
          <LandingHeader />
          <div>
         
            {children}
            <Notifier />
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}
