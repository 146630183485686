import { reducer as connectWalletReducer } from "./connectWallet";
import { reducer as disconnectWalletReducer } from "./disconnectWallet";
import { reducer as fetchDashboardReducer } from "./fetchDashboard";
import { reducer as fetchPoolDetailsReducer } from "./fetchPoolDetails";
import { reducer as fetchRewardPoolsDetailsReducer } from "./fetchRewardPoolsDetails";
import { reducer as fetchWithdrawReducer } from "./fetchWithdraw";
import { createWeb3Modal } from "features/web3";
const reducers = [
  connectWalletReducer,
  disconnectWalletReducer,
  fetchDashboardReducer,
  fetchPoolDetailsReducer,
  fetchRewardPoolsDetailsReducer,
  fetchWithdrawReducer
];

const initialState = {
  address: "",
  web3: null,
  connected: false,
  web3Modal:createWeb3Modal(),
  networkId: Number(process.env.REACT_APP_NETWORK_ID),
  detail: {},
  userDetails: [],
  fetchWithdrawPending:[false]

};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
