export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  escrowedRewards: [
    {
      tokenName: "SEA",
      tokenAddress: "0xff9fea2666ac0b074a1a53579ff95add4df0601a",
      escrowPool: "0xf202abD5c37a21994ECC90002873cECC5F69E80F",
      liquidityMiningManager: "0x87246a122337fc1de326840049B3431b1885E55a",
      view: "0xA93a197D49afEda3E8C6685A54f66F4B35a571b4"
    }
  ],
  singleTokenPool: {
    address: "0xC98F0fB7B729aF60E6631E0F8837Bd8c7E75efA6",
  },
  lpPool: {
    address: "0xC98F0fB7B729aF60E6631E0F8837Bd8c7E75efA6",
  },
  uniswapLpPairAddress : {
    address: "0x2cF862D1303726e8A54571fB34620D5870Cc9e7e"
  },
  copperLaunchPoolContract:{
    address: "0x55eBdd8e500e7b122e01eBf0b516C536280B2a1B"
  },
  balancerVault: {
    address: "0xBA12222222228d8Ba445958a75a0704d566BF2C8",
    poolId: "0x55ebdd8e500e7b122e01ebf0b516c536280b2a1b0002000000000000000001e6"
  },
  oneInchRouter:{
    address:"0x1111111254fb6c44bac0bed2854e76f90643097d"
  }
};
