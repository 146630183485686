import {
  grayColor,
  primaryColor,
  whiteColor,
  mlAuto,
  hexToRgb,
} from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  headerConnect:{
    
  },
  hearderAddress:{
    
  }
});

export default styles;
